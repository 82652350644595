interface CollapsibleCardProps {
  id: string;
  title: string;
  content: JSX.Element;
}

export function CollapsibleCard(props: CollapsibleCardProps): JSX.Element {
  const bodyID = props.id + "-body";

  return (
    <div className="card my-2">
      <a
        className="card-header"
        data-bs-toggle="collapse"
        href={"#" + bodyID}
        aria-expanded="false"
        aria-controls={bodyID}
        id={props.id}
      >
        <h3>{props.title}</h3>
      </a>
      <div className={"collapse card-body"} id={bodyID}>
        {props.content}
      </div>
    </div>
  );
}
