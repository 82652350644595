import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function Chronologie() {
  return (
    <>
      <h1 className="mt-5">Was ist bisher passiert?</h1>
      <p>
        Durch den zivilen Ungehorsam in Form der 12-tägigen Besetzung im Nov/Dez
        2022 wurden primär folgende Punkte erreicht:
      </p>
      <ul>
        <li>Die Uni verpflichtete sich bis 2028 klimaneutral zu werden.</li>
        <li>
          Es gab ein erstes Forum zu Klimagerechtigkeit der Uni Augsburg -
          Gerade an Bildungseinrichtungen muss die größte Bedrohung des 21.
          Jahrhunderts diskutiert und Strategien entwickelt werden.
        </li>
        <li>
          Es wurde ein Impuls angestoßen, der immer noch auf
          Universitätsmitglieder wirkt - Wir können Veränderung nur gemeinsam
          gestalten.
        </li>
      </ul>
      <br></br>
      <h1 className="mt-5">12 Tage Besetzung von Hörsaal I</h1>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag1b.jpg"
          alt="Tag 1"
        />
      </div>
      <i>Montag, 28.11.</i>
      <br></br>
      <p>
        11:25 - Wir gehen mit 20 Menschen in die Informatik-Vorlesung in HS I.
        <br></br>
        12:30 - Die 11:45-Uhr-Vorlesung findet online statt. Wir gestalten HS I
        mit Plakaten, Bannern und sonstigem und richten uns ein.<br></br>
        13:00 - Wir geben Interviews mit AZ und BR (Siehe "Presse").<br></br>
        14:00 - Kanzler und Präsidentin werden von uns angerufen. Der Kanzler
        sagt, wir sollten eine Mail mit unserem Anliegen schreiben. Das tun wir.
        <br></br>
        16:00 - Es kommt eine Antwort des Sekräteriats der Präsidentin per Mail:
        Wir haben ein Angebot für ein Verhandlungsgespräch mit der Unileitung
        und AstA-Vorstand.<br></br>
        18:00 - Plenum. Vorbereitung auf das morgige Verhandlungsgespräch.
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag2.jpg"
          alt="Tag 2"
        />
      </div>
      <i>Dienstag, 29.11.</i>
      <br></br>
      <p>
        08:15 - Die erste Vorlesung des Tages. Zu Beginn und am Ende von
        Vorlesungen informieren wir über unsere Forderungen und kommen mit Profs
        und Studierenden ins Gespräch.<br></br>
        14:00 - 1. Verhandlungsgespräch mit Unileitung: Wir verhandeln nicht
        hinter verschlossenen Türen. Daher sprechen wir in diesem ersten
        Gespräch nur über den Modus von öffentlichen Verhandlungen. Uns wird
        dann vorgeschlagen am Donnerstag, den 31.11. um 10:00 Uhr morgens im
        Zentrum für Klimaresilienz über Klimaneutralität zu sprechen. Wir
        kündigen schon in dem Gespräch an, dass dies nicht unseren Anforderungen
        entspricht und wir sowieso nur gemeinsam im Plenum darüber entscheiden.
        Aus folgenden Gründen ist ein solches Angebot inakzeptabel: 1. Zu
        kurzfristig, 2. Universitätsmitgleider haben um 10:00 Veranstaltungen,
        3. In diesem Raum im ZfK ist nicht ausreichend Platz für eine Diskussion
        auf Augenhöhe, 4. Wir sprechen über Klimagerechtigkeit, nicht nur über
        Klimaneutralität!<br></br>
        19:00 - Plenum. Wir diskutieren, ob wir das (öffentliche)
        Gesprächsangebot der Unileitung annehmen wollen. Entscheiden uns
        dagegen. Wir wollen den studentischen Konvent mit einbeziehen und
        stellen einen Antrag.
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag3.jpg"
          alt="Tag 3"
        />
      </div>
      <i>Mittwoch, 30.11.</i>
      <br></br>
      <p>
        11:00 - Es wird am Sonntag, den 04.12. die erste außerordentliche
        Konventssitzung seit 6 Jahren einberufen, damit sich die StuVe zu
        unseren Forderungen positioniert.<br></br>
        13:00 - Wir geben Interviews mit AZ und BR (Siehe "Presse").<br></br>
        13:30 - Küche für alle vor HS I.<br></br>
        15:00 - Input der studentischen Vertretung (StuVe) zu Unistrukturen der
        StuVe. Wir lernen, welche Partizipationsmöglichkeiten Studierende an der
        Uni haben. Diese sind sehr eingeschränkt.<br></br>
        20:00 - Plenum.
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag4.jpg"
          alt="Tag 4"
        />
      </div>
      <i>Donnerstag, 01.12.</i>
      <br></br>
      <p>
        10:00 - Das "öffentliche" Gespräch mit Unileitung, ZfK und
        Interessierten findet ohne uns statt, da auf unsere Anforderungen nicht
        eingegangen wurde (
        <a
          href="pdf/Statement - Warum End Fossil Augsburg morgen nicht mit der Unileitung verhandelt.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Statement - Warum End Fossil Augsburg morgen nicht mit der Unileitung
          verhandelt
        </a>
        ). Die Uni bekennt sich öffentlich dazu, bis 2028 Klimaneutral zu
        werden!<br></br>
        11:30 - Der evengelische Hochschulpfarrer besucht unsere Besetzung in HS
        I und versucht zu mediieren.<br></br>
        12:30 - Daraufhin erscheint er zusammen mit der Hochschulleitung und
        Menschen aus dem ZfK und WZU im besetzten HS I und wir setzen uns
        gemeinsam vor den Hörsaal. Es kommt zum ersten Gespräch auf Augenhöhe
        seit Besetzungsbeginn. Es scheint so, als nehme die Unileitung unsere
        Forderungen ernst, es entsteht so etwas wie ein Vertrauensverhältnis.
        Wir verhandeln erneut über ein anderes Format für ein öffentliches,
        gleichberechtigtes Gespräch. All unsere Anforderungen werden erfüllt und
        in einer Woche - am Donnerstag, den 08.12. soll das "erste Forum zu
        Klimagerechtigkeit der Uni Augsburg" stattfinden. Wir haben unser erstes
        Zwischenziel erreicht. Ein öffentliches Forum zu dem krassesten Problem
        des 21. Jahrhunderts...<br></br>
        14:00 - Cops kommen kurz in HS I, sind aber sofort wieder weg.<br></br>
        18:00 - Plenum. Wir feiern unser bisheriges Auftreten seit
        Besetzungsbeginn. Wir haben schon an Tag 3 einiges erreicht: 1.
        Klimaneutralität bis 2028, 2. Öffentliches Forum zu Klimagerechtigkeit
        und 3. Außerordentliche Sitzung des studentischen Konvents.
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag5.jpg"
          alt="Tag 5"
        />
      </div>
      <i>Freitag, 02.12.</i>
      <br></br>
      <p>
        12:00 - Mittagsplenum.<br></br>
        13:00 - BR interviewt und will wissen, wie es zu dem Forum gekommen ist
        (Siehe "Presse").<br></br>
        14:00 - Nachdem Unileitung im gestrigen Gespräch auch anklingen lassen
        hat, dass wir HS I übers Wochenende räumen sollen, machen wir noch
        einmal klar, dass wir bleiben.<br></br>
        16:00 - Wir schreiben den Konventsantrag fertig.<br></br>
        18:00 - Konzert "Wollstiefel" in HS I.<br></br>
        19:00 - Küche für alle.<br></br>
        21:00 - Plenum. Wir bereiten uns aufs Wochenende vor...
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag6.jpg"
          alt="Tag 6"
        />
      </div>
      <i>Samstag, 03.12.</i>
      <br></br>
      <p>
        Chillen in der leeren Uni. Vorbereiten auf die Konventssitzung, in der
        die Studierendenschaft sich hinter unsere Forderungen stellen könnte.
        kein Plenum. Ausruhen.
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag7.jpg"
          alt="Tag 7"
        />
      </div>
      <i>Sonnstag, 04.12.</i>
      <br></br>
      <p>
        12:00 - Frühstücken im besetzten HS I.<br></br>
        14:00 - Interview mit dem Presstige Hochschulmagazin(
        <a
          href="https://presstige.org/2022/12/end-fossil-augsburg-kaempft-fuer-mehr-klimagerechtigkeit/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i>End Fossil Augsburg kämpft für mehr Klimagerechtigkeit</i>
        </a>
        ).<br></br>
        16:00 - Vorbereiten auf die Konventssitzung.<br></br>
        18:00 - Konventssitzung. Wir stellen unsere Forderungen vor und erklären
        unser Vorgehen. Der studentische Konvent erklärt mit überwältigender
        Mehrheit die Unterstüzung unserer Forderungen - insbesondere für das 1.
        Forum zu Klimagerechtigkeit am kommenden Donnerstag.
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag8.jpg"
          alt="Tag 8"
        />
      </div>
      <i>Montag, 05.12.</i>
      <br></br>
      <p>
        12:00 - Treffen mit Unlieitung zur "Architektur" des Forums.<br></br>
        19:00 - Plenum. Wir bereiten uns auf das Forum vor und besprechen, unter
        welchen Voraussetzungen wir die Besetzung fürs erste verlassen.
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag9.jpg"
          alt="Tag 9"
        />
      </div>
      <i>Dienstag, 06.12.</i>
      <br></br>
      <p>
        10:00 - Weiterhin sind wir vor und nach jeder Vorlesung präsent und
        diskutieren unsere Forderungen.<br></br>
        19:00 - Plenum. Wir bereiten uns auf das Forum vor und insbesondere, wie
        es danach mit End Fossil Augsburg weitergeht.
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag10.jpg"
          alt="Tag 10"
        />
      </div>
      <i>Mittwoch, 07.12.</i>
      <br></br>
      <p>
        16:30 - Plenum. Wir bilden Expert*innengruppen für unser Forderungen, um
        beim morgigen Forum unsere Forderungen voranzutreiben.
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag11.jpeg"
          alt="Tag 11"
        />
      </div>
      <i>Donnerstag, 08.12.</i>
      <br></br>
      <p>
        15:30 - 1. Forum zu Klimagerechtigkeit der Uni Augsburg.<br></br>
        32 Menschen aller Bereiche auf dem Podium, etwa 200 Menschen im
        Publikum. Die dreistündige Diskussion war intensiv. Allen Beteiligten
        ist klar, dass sich vieles verändern muss. Partizipation und Transparenz
        stellen dafür auf dem Weg zu einer klimagerechteren Uni die Grundpfeiler
        dar.<br></br>
        18:30 - Wir kündigen an, die Besetzung fürs erste zu beenden, wenn es
        weitere Foren geben wird und die angekündigten Maßnahmen vorangetrieben
        werden.<br></br>
        20:00 - Letztes Plenum der Besetzung. Ein bisschen Tanz muss sein!
      </p>
      <br></br>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/tag12.jpg"
          alt="Tag 12"
        />
      </div>
      <i>Freitag, 09.12.</i>
      <br></br>
      <p>
        10:00 - Wir genießen die letzten Vorlesungen und diskutieren weiterhin
        mit Interessierten.<br></br>
        12:00 - Wir bauen ab und hinterlassen HS I so, wie wir ihn vor 12 Tagen
        vorgefunden haben. Wir haben wahnsinnig viel erreicht. Danke an alle
        Beteiligten!
      </p>
      <br></br>
      <br></br>
      <h1 className="mt-5">1. Forum zu Klimagerechtigkeit der Uni Augsburg</h1>
      <br></br>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "800px",
            marginBottom: "25px",
          }}
          src="img/Forum1.jpg"
          alt="1.Forum"
        />
      </div>
      <a
        href="https://www.forumaugsburg.de/s_6kultur/Wissenschaft/221221_erstes-oeffentliches-forum-tagt-an-der-uni-erfolg-f%C3%BCr-end-fossil-augsburg/index.htm"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i>(Quelle: Forum solidarisches und friedliches Augsburg)</i>
      </a>
      ).
      <br></br>
      <br></br>
      <i>
        Donnerstag, 08.12.2022 - 15:30-19:00 - Auditorium Musik & Kunst -
        G-Gebäude
      </i>
      <br></br>
      <p>
        Wir haben ein öffentliches Format ausgehandelt, bei dem zum ersten Mal
        an der Uni Augsburg über die Klimakatastrophe diskutiert wurde - und
        zwar öffentlich und auf Augenhöhe mit allen Bereichen. Aus den
        verschiedenen Bereichen (Präsidium, Professorium, Mittelbau,
        wiss.-stütz.-Personal, Studierendenvertretung und -schaft und End Fossil
        Augsburg) saßen 33 Menschen auf dem Podium und diskutierten vor einem
        Publikum von etwa 200 Menschen drei Stunden über die Forderungen der
        Besetzung. Im Zentrum der Diskussion standen dabei die Themen
        Partizipation und Transparenz in Verbindung mit Klimaneutralität. Es ist
        klar geworden, dass erheblicher Nachholbedarf in diesen Punkten besteht.
        Die Unileitung verpflichtete sich, Partizipationsformate zu schaffen,
        bei denen alle Universitätsmitgleider den Prozess zu Klimaneutralität
        mitgestalten können. Aus unserer Perspektve könnte vor allem die
        Etablierung eines Green Office Informationen bündeln und eine
        partizipative und transparente Plattform bieten, um den Prozess zur
        echten Klimneutralität gemeinsam zu gestalten.
      </p>
      <p>
        {" "}
        Leider ist die Dimension des Begriffs Klimagerechtigkeit noch nicht
        ausreichend in alle Ebenen der Universität durchgedrungen. Innerhalb der
        Klimaproblematik legt die Unileitung den Fokus auf Klimaneutralität und
        vernachlässigt Fragen der sozialen Gerechtigkeit. Für uns sind - wie es
        ebenfalls der wissenschaftliche Konsens darstellt - diese Fragen
        untrennbar. Daher bleiben wir dran und machen Klimagerechtigkeit zu
        einem zentralen Thema an dieser Universität!
      </p>
      <p>
        Hier das Protokoll vom{" "}
        <a
          href="pdf/Protokoll - 1.Forum (08.12.22).pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          1. Forum zu Klimagerechtigkeit der Uni Augsburg
        </a>
        .
      </p>
      <br></br>
      <h1 className="mt-5">Arbeitstag zur Governance und Unistrukturen</h1>
      <br></br>
      <br></br>
      <i>Dienstag, 31.01.2023 - 15:30-19:00 - Y-Gebäude (Y-1002)</i>
      <p>
        Um die Grundordnungsänderung, die im Zuge des neuen bayrischen
        Hochschulinnovationsgesetz (BayHIG) notwendig wird, transparent und
        partizipativ mit allen Universitätsangehörigen gemeinsam zu gestalten,
        haben wir einen Arbeitstag ins Leben gerufen. Etwa 30 Menschen aller
        verschiedener Statusgruppen (Professorium, Mittelbau,
        wiss.-stütz.-Personal und Studierendenschaft) waren am Start. Gemeinsam
        verschafften wir uns zunächst einen Überblick über die universitären
        Entscheidungs- und Gremienstrukturen und die einhergehenden
        Veränderungen durch das BayHIG. In Form einer regen Diskussion
        versuchten wir gemeinsame Änderungsvorschläge herauszukristalisieren,
        sodass die Universität auf einen klimagerechten und sozialverträglichen
        Weg gebracht werden kann.<br></br>
      </p>
      <p>
        Hier das Protokoll vom{" "}
        <a
          href="pdf/Protokoll - Arbeitstag zur Governance und Unistrukturen (31.01.23).pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Arbeitstag zur Governance und Unistrukturen (31.01.23)
        </a>
      </p>
      <p>
        Alle Infos zu unserem weiteren Vorgehen und Materialien zur Unistruktur
        findest Du bei{" "}
        <a
          href="https://www.endfossilaugsburg.de/transparenz"
          rel="noreferrer"
          target="_blank"
        >
          Transparenz
        </a>{" "}
        und{" "}
        <a
          href="https://www.endfossilaugsburg.de/partizipation"
          rel="noreferrer"
          target="_blank"
        >
          Partizipation
        </a>
        .
      </p>
    </>
  );
}
