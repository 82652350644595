import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function Aktuelles() {
  return (
    <>
      <div className="d-flex flex-column">
        <img
          style={{ maxWidth: "300px", width: "100%", margin: "auto" }}
          src="img/logo website.png"
          alt="Logo End Fossil Augsburg"
        />
      </div>
      
       <p className="mt-3">
        <i>15.05.2023</i>
        <br />
        <p>
        Sieh's mal postkolonial <br></br><br></br>
        23. Mai - 22. Juni <br></br><br></br>
        In der AG Postkolonial der Uni Augsburg haben wir eine Veranstaltungsreihe für das Sommersemester 2023 organisiert. Mit vier spannenden Veranstaltungen wollen wir postkoloniale Perspektiven in den Unikontext und die Stadt Augsburg tragen. <br></br>
        Im Folgenden werden die Veranstaltungen genauer beschrieben.<br></br><br></br>
        Achtung: Aufgrund begrenzter Teilnehmer*innenzahl ist für die Veranstaltung am 26.05. eine Anmeldung erforderlich, unter:  
              <a
                href="www.fugger-und-welser-museum.de/veranstaltungen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fugger & Welser Museum: Veranstaltungen
              </a>. 
              
              Diese Veranstaltung wird gestreamt, über:   
              <a
                href="https://www.youtube.com/watch?v=EFJtXd1i8ls"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stream: „The Welser Phantom“
              </a><br></br> 
        Für die Veranstaltung am 07.06., meldet euch bitte unter claas.henschel@augsburg.de an.<br></br> <br></br>
        Wir freuen uns auf Euch!<br></br>
        </p>
        <br />
        <div className="d-flex flex-column">
          <img
            style={{ maxWidth: "800px" }}
            src="img/SIEH'S MAL PSTKOLONIAL (Plakat).png"
            alt="PlakatPostkolonial"
          />
        </div>

        <hr />
        23. Mai - 18:00 Uhr - Mamparo: Film und Gesprächsrunde zu venezolanischen Perspektiven auf Augsburger Kolonialgeschichten<br></br>
        <div className="d-flex flex-column">
          <img
            style={{ maxWidth: "800px" }}
            src="img/Mamparo.jpg"
            alt="Mamparo"
          />
        </div>
        Über die Kolonialaktivitäten der Welservertreter in Venezuela zwischen 1528 und 1546 ist in der kollektiven Erinnerung Augsburgs wenig bekannt. Im Jahre 1528 wurde der Augsburger Handelsfamilie der Welser das von Spanien annektierte Gebiet ‘Klein-Venedig’ (heute als Venezuela bekannt) zugesprochen, weil der damaligeKaiser Karl V. sich bei den Welsern hoch verschuldet hatte. In diesem Zusammenhang wurde auch die Stadt Santa Ana de Coro gegründet, die bei der Gründung durch die Welservertreter „Neu-Augsburg“ genannt wurde. Noch weniger ist darüber bekannt, wie Venezolaner*innen an die Welservetreter erinnern. Der Dokumentarfilm 'Mamparo' zeigt eine vielstimmige Perspektive dieser Erinnerung. Der Film wird von einem digitalen Interview gerahmt, das Projektmitarbeitende Gregor Büchele und Timon Bürbaumer im April 2022 mit den Filmemachern Adrían Condez und Ulises León geführt haben. Darin werden die Motivationen und weiterführenden Überlegungen der Filmemacher verdeutlicht. In einer offenen Gesprächsrunde wird im Anschluss die Möglichkeit eröffnet zu diskutieren, was diese Perspektiven für postkoloniale Erinnerungskultur in Augsburg bedeuten könnten. Film mit Interview (OmU, 61 Minuten) mit anschließender Gesprächsrunde. Film und Interview sind auf spanischer Sprache mit deutschen Untertiteln.
        <br></br>Termin: Dienstag, 23.05.2023 von 18.00 bis ca. 20.30 Uhr
        <br></br>Universität Augsburg, Raum: D-2110, ohne Voranmeldung

        <hr />
        26. Mai - 16:00 Uhr - „The Welser Phantom“: How has the Welser Colony in Venezuela been remembered?<br></br>
        <div className="d-flex flex-column">
          <img
            style={{ maxWidth: "800px" }}
            src="img/WelserPhantom.jpg"
            alt="WelserPhantom"
          />
        </div>
        Die Welser aus Augsburg bekamen zwischen 1528 und 1546 die spanische Kolonie Venezuela als „Lehen“, aber kaum jemand in Deutschland kennt diese Geschichte. Dabei handelt es sich doch um eines der ersten und gewaltvollsten Kapitel deutscher Kolonialgeschichte, dessen Aufarbeitung im Kontext aktueller Auseinandersetzungen um Kolonialismus in Deutschland umso notwendiger ist.<br></br><br></br>
        Diese seltsame und oft vernachlässigte Episode deutscher Kolonialgeschichte ist zentraler Forschungsgegenstand von Dr. Giovanna Montenegro. Sie beschäftigt sich auch mit der Erinnerung an die Welser und erhielt 2019 von der Latin American Studies Association eine Auszeichnung für ihren Artikel „The Welser Phantom: Apparitions of the Welser Venezuela Colony in Nineteenth and Twentieth-century German Cultural Memory“. Darin zeigt sie, dass es in der deutschen Geschichte und Gegenwart ein ‚Welser-Phantom‘ gegeben hat. Dieser Begriff spielt auf die Erinnerung an die Welser-Kolonie an, die vor allem in der Hochphase des deutschen Kolonialismus im 19. und frühen 20. Jahrhundert wichtiger Referenzpunkt für die Legitimation kolonialer Bestrebungen war. Auch deshalb war und bleibt die Erinnerung an die Welser politisch relevant und erfordert daher einen kritischen und verantwortungsvollen Blick in aktuellen Debatten um postkoloniale Erinnerung.<br></br><br></br>
        Ihr kürzlich bei University of Notre Dame Press erschienenes Buch „German Conquistadors in Venezuela: The Welsers' Colony, Racialized Capitalism, and Cultural Memory“ stellt einen wichtigen Beitrag in der internationalen Auseinandersetzung mit deutscher Kolonialgeschichte dar. Darin setzt sich Montenegro mit zahlreichen Primärdokumenten auseinander, und untersucht eine Geschichte massiver Gewalt gegen indigene und afrikanische Menschen. Sie richtet den Blick insbesondere darauf, wie verschiedene Generationen in Deutschland sich an die Welser-Herrschaft in Venezuela erinnert haben, und wie diese Erinnerungen politisch genutzt wurden.<br></br><br></br>
        Montenegro stellt in ihrem Vortrag ihr aktuelles Buch vor und stellt Bezüge zu aktuellen Diskursen um postkoloniale Erinnerungskultur her. Der Vortrag und die anschließende Diskussion finden auf englischer Sprache statt. Plätze vor Ort sind begrenzt. Wir bitten um Anmeldung unter www.fugger-und-welser-museum.de/veranstaltungen/. Außerdem wird der Vortrag gestreamt. Der Link wird über die Webseite des Fugger-und-Welser-Erlebnismuseums zur Verfügung gestellt.<br></br><br></br>
        Die Veranstaltung findet in Kooperation mit dem Fugger-und-Welser-Erlebnismuseum statt im Rahmen des Projekts „Deutungskämpfe in der Kolonialität des Friedens“ des vom BMBF finanzierten Forschungsverbunds „Bayerisches Zentrum für Friedens- und Konfliktforschung: Deutungskämpfe im Übergang“.<br></br><br></br>
        
        <hr />
        07. Juni - 15:30 - Postkolonialer Stadtrundgang & Museumsbesuch im Schaezlerpalais<br></br>
        <div className="d-flex flex-column">
          <img
            style={{ maxWidth: "800px" }}
            src="img/Stadtrundgang.jpg"
            alt="Stadtrundgang"
          />
        </div>
        Postkolonialer Stadtrundgang mit Claas Henschel, sowie im Anschluss optional ein Rundgang durch das Schaezlerpalais zum Thema "Schwarze Menschen in der Kunst". Die Teilnahme ist kostenlos, Anmeldungen bitte vorher per email an Claas.Henschel@augsburg.de.<br></br><br></br>
        
        <hr />
        22. Juni - 16:00 - Klimagerechtigkeit aus postkolonialer Perspektive<br></br>
        
      </p>

      <hr />
      <hr />
      <p className="mt-3">
        <i>04.05.2023</i>
        <br />
        <p>
        Das neue Semester hat begonnen. Es gibt viel zu tun an der Uni Augsburg. Ein paar neue Menschen waren am vergangenen Dienstag beim Kennenlernen & Filmabend. Nun werden wir diesen Sonntag konkreter. Wenn du dabei sein willst, schreib uns eine Mail (endfossilaugsburg@riseup.net). Komm vorbei zu unserem offenen Plenum am Sonntag um 18h - wir freuen uns auf dich!
        </p>
        <br />
        <div className="d-flex flex-column">
          <img
            style={{ maxWidth: "800px" }}
            src="img/OffenesPlenum.jpeg"
            alt="Offenes Plenum"
          />
        </div>

        <hr />
        <i>16.04.2023</i>
        <br />

        <p>
          Pünktlich zum Start des Sommersemesters 2023 aktualisieren wir unsere
          Forderungen. In den vergangenen sechs Monaten haben wir viel gelernt
          und Einblicke in die Strukturen und Probleme der universitären
          Prozesse erlangt.
        </p>
        <br />
        <p>
          Nach wie vor sind wir fest entschlossen die Universität zu verändern.
          Auch wenn die Universitätsleitung eine Zermürbungstaktik versucht -
          indem nicht auf uns reagiert wird und uns im letzten Moment
          Räumlichkeiten für unsere Treffen gestrichen werden - werden wir
          weiter dran bleiben. Vor allem auf folgenden Punkten legen wir im
          Sommersemester 2023 den Fokus:
        </p>
        <br />
        <p>
          <ul>
            <li>
              Die Etablierung eines <i>Green Office</i> ist die Grundlage für
              den Weg zur Klimaneutralität 2028. So können Informationen und
              Ideen gebündelt, Menschen aktiviert und ein gemeinsam abgestimmter
              Prozess begonnen werden. Engagierte begegnen sich auf Augenhöhe
              und Synergieeffekte werden erzielt.
            </li>

            <li>
              Nachdem das neue bayrische Hochschulinnovationsgesetz in Kraft
              getreten ist, muss die Uni Augsburg dieses in ihrer Grundordnung
              verankern. Diesen Prozess der <i>Grundordnungsänderung</i> wollen
              wir gemeinsam mit allen Statusgruppen partizipativ und transparent
              gestalten und so die Uni auf einen klimagerechten und
              sozialverträglichen Weg bringen.
            </li>

            <li>
              Wir werden den Prozess der <i>Dekolonialisierung der Uni</i>{" "}
              vorantreiben, sowohl in der Lehre, als auch die universitären
              Strukturen betreffend. Im Rahmen der Statusgruppen-übergreifenden
              AG Postkolonial veranstalten wir im Sommersemester 2023 die
              postkoloniale Veranstaltungsreihe "Sieh's mal postkolonial" (Alle
              Infos:{" "}
              <a
                href="https://www.facebook.com/people/AG-Postkolonial-der-Universit%C3%A4t-Augsburg/100091368198490/"
                target="_blank"
                rel="noopener noreferrer"
              >
                AG Postkolonial der Universität Augsburg
              </a>
              ).
            </li>
          </ul>
        </p>
      </p>
      <br />

      <hr />
      <i>Pressemitteilung vom 30.01.2023</i>
      <h1>Ist die Universitätsleitung nicht bereit für Veränderungen?</h1>
      <p>
        Die Leitung der Universität Augsburg verweigert Studierenden sich über
        die Entscheidungsstrukturen der Universität auszutauschen. Vergangenes
        Jahr besetzten Aktivist*innen der Gruppe End Fossil Augsburg den größten
        Hörsaal, um die notwendigen Veränderungen auf dem Weg zu einer
        klimagerechten Uni zu forcieren. Die Universitätsleitung zeigte sich
        diskussionsbereit und sicherte den Aktivist*innen öffentlich abgehaltene
        Foren zu.
      </p>
      <p>
        Das erste Forum, zu den Themen Klimaneutralität, Transparenz und
        studentisches Mitspracherecht, fand am 08. Dezember 2022 von 15 bis 19
        Uhr mit etwa 200 Interessierten statt. Nachdem ein zweites Forum am 31.
        Janaur 2023 über Governance und die Strukturen der Uni - also die Art
        und Weise wie dort Entscheidungen getroffen werden - von der Unileitung
        abgelehnt wurde, lud die Gruppe alle Beschäftigten und Studierenden zu
        einem Arbeitstag zu diesem Thema ein. Das neu errichtete Zentrum für
        Klimaresilienz stellte dafür bereits am 16.01. den großen Seminarraum
        zur Verfügung. Doch am 25.01., also 6 Tage vor dem Treffen, schreitete
        die Unileitung ein, und untersagte es.
      </p>
      <p>
        End Fossil setzt sich für Klimagerechtigkeit ein. Das bedeutet über
        Klimaschutz hinaus auch soziale und globale Gerechtigkeit, denn das eine
        kann ohne das andere nicht existieren. Deshalb hat die Gruppe neben
        Klimaneutralität, welche sich die Universität noch während der
        Hörsaalbesetzung bis 2028 zum Ziel gesetzt hat, weitere Forderungen, wie
        zum Beispiel soziale Uni oder Mitspracherecht und
        Partizipationsmöglichkeiten.
      </p>
      <p>
        "Die Uni ist hierarchisch aufgebaut, ähnlich wie ein Unternehmen"
        erklärt Aktivist*in Marvin Peitsch. "Mitsprache ist sehr schwierig und
        Diskurse werden nicht öffentlich geführt. Diese Arbeit wird von Seiten
        der Studierenden momentan durch einige wenige ehrenamtliche
        Vertreter*innen geleistet." Zudem gibt das neue
        Hochschulinnovationsgesetz Spielräume, die universitären
        Entscheidungsstrukturen demokratischer zu gestalten. Welche Spielräume
        das sind, und was dabei möglich ist, darüber sollte sich eigentlich am
        31. Januar mit dem angekündigten Arbeitstag ausgetauscht werden.
      </p>
      <p>
        "Wir fordern einen gleichberechtigten Prozess, der alle Beteiligten mit
        einschließt, und nicht eine Entscheidung über alle Köpfe hinweg. Es gibt
        wahnsinnig viele Ideen und Motivation die notwendigen Veränderungen zu
        gestalten, aber die Menschen haben derzeit nicht die Möglichkeit dies zu
        tun - erst wenn ihnen in gewissen Bereichen Entscheidungskompetenzen
        zugesprochen werden, kann eine demokratische Veränderung geschehen", so
        Aktivist*in Lukas Schuster.
      </p>
      <p>
        Das Referat für Hochschulpolitik der Studierendenvertretung hat nun
        einen Raum im Gebäude Y für den Arbeitstag organisiert. Alle
        Studierenden und Beschäftigten der Universität sind eingeladen sich am
        Dienstag, den 31. Januar dort über Demokratie an der Uni auszutauschen.
      </p>
      <p>
        “Uns ist das Handeln der Unileitung völlig unverständlich. In den
        Verhandlungsgesprächen während der Besetzung haben wir ein
        Vertrauensverhältnis aufgebaut. Wir hatten das Gefühl, die
        Entscheidungsträger*innen dieser Universität wären bereit, gemeinsam den
        Prozess der Veränderung zu beschreiten”, so Aktivist*in Tamara Rebel.
        “Mit derartigen Machtspielchen hätten wir nicht gerechnet. Wir hoffen
        nach wie vor auf die Kooperationsbereitschaft der Unileitung.”
      </p>
    </>
  );
}
