import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function Partizipation() {
  return (
    <>
      <br></br>
      <p>
        Wir haben einen ersten Anstoß gegeben, um die Universität auf einen
        klimagerechten Weg zu bringen. Damit die "Klimaneutralität bis 2028"
        kein leeres Versprechen bleibt und der Unileitung nur zum greenwashen
        dient, braucht es dich! Egal ob Du Student*in, Mittelbau-Mensch oder
        Prof bist, schließ dich uns an. Nur gemeinsam können wir Synergieeffekte
        schaffen und die Uni auf Grundlage von Transparenz und Partizipation
        umgestalten und auf einen zukunftsfähigen Weg bringen.
        <br></br>
        <i>
          {" "}
          Aktuell plenieren wir jeden zweiten Sonntag. Wenn du dabei sein
          willst, schreib uns eine Mail oder über Instagram.
        </i>
      </p>
      <br></br>
      <b>
        Hier beschreiben wir, worauf wir uns im kommenden Semester konzentrieren
        und wie Du dabei partizipieren kannst.
      </b>
      <br></br>
      <br></br>
      <br></br>
      <h1 id="partizipationklimaneutralität">Klimaneutralität</h1>
      <i>Ein Green Office ist notwendig!</i>
      <br></br>
      <p>
        Ein Green Office ist eine Plattform, die es allen
        Universitätsmitgliedern (Profs, Mittelbau, Studierenden und
        Mitarbeiter*innen) ermöglicht Nachhaltigkeit in Lehre, Forschung,
        Betrieb, Hochschulgemeinschaft und Governance zu verankern. Die
        Nachhaltigkeitsbemühungen von Studierenden haben oft nur begrenzte
        Auswirkungen, da es uns an finanziellen Ressourcen und institutionellem
        Zugang fehlt. Im Gegensatz zu herkömmlichen Initiativen, ermöglicht ein
        Green Office eine nachhaltige Gestaltung in Zusammenarbeit und erhält
        dabei finanzielle Unterstützung, ein Mandat und Büroräume von der
        Hochschulleitung. Derzeit gehen Initiativen zu einer nachhaltigeren
        Universität hauptsächlich von ehrenamtlich arbeitenden Menschen aus.
        Dies ist für eine klimagerechte Transformation eindeutig zu wenig. Mit
        der neuen Stelle einer Klimamanager*in wird erstmals auch eine bezahlte
        Stelle geschaffen. Wir sind der Überzeugung, dass ein Green Office
        diese*n unterstützen wird.<br></br>
        <br></br>
        Mit der Etablierung eines Green Office wird die Nachhaltigkeit in der
        Hochschulstruktur verankert. Die Zusammenarbeit von Menschen aus
        verschiedenen Statusgruppen erzeugt Synergien und inspiriert womöglich
        noch mehr Menschen die Hochschule aktiv mitzugestalten. Durch die
        direkte Angliederung an der Universität stehen finanzielle Mittel und
        Stellen zur Verfügung. Außerdem sorgt ein Green Office für mehr
        Sichtbarkeit, wenn eine zentrale Anlaufstelle für Themen der
        Klimagerechtigkeit geschaffen wird.<br></br>
        <br></br>
        Du kannst uns unterstützen, indem du mit uns mit der Unileitung
        verhandelst und so die Einführung eines Green Office an der Uni Augsburg
        vorantreibst. Informiere dich, wie es an anderen Unis läuft und erstelle
        mit uns ein Konzept, das ein Green Office an der Uni Augsburg
        konstituiert.
        <br></br>
      </p>
      <br></br>
      <h1 id="partizipationgrundordnung">Grundordnungsänderung</h1>
      <i>
        Demokratische Strukturen schaffen, in denen alle gleichberechtigt
        partizipieren können!
      </i>
      <br></br>
      <br></br>
      <h1 id="partizipationdekolonialisierung">Dekolonialisierung</h1>
      <i>Kritische Reflexion über Eurozentrismus, Fortschritt und Wohlstand!</i>
      <br></br>
      <br></br>
      <h1 id="liveticker">Live-Ticker</h1>
      Folge unserem Live-Ticker auf Telegram <br />
      <a href="https://t.me/+12nZr6xwo9dkMzdi" target="_blank" rel="noreferrer">
        <i className="bi bi-telegram"></i>
      </a>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "300px",
            marginBottom: "25px",
          }}
          src="img/ZU.png"
          alt="Ziviler Ungehorsam"
        />
      </div>
    </>
  );
}
