import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function Transparenz() {
  return (
    <>
      <br></br>
      <p>
        Wissensweitergabe ist an Universitäten ein großes Manko. Daher wollen
        wir eine Plattform bieten, auf der unser Wissen der vergangenen sechs
        Monate gespeichert wird und zukünftige Hochschulgruppen und Initiativen
        oder eine neue End Fossil Augsburg Generation nicht von Null anfangen
        muss. Ganz unten auf dieser Seite findest Du Protokolle, Infos und
        Materialien.
      </p>
      <br></br>

      <h1 id="klimaneutralität">Klimaneutralität</h1>
      <p>
        Erst durch die Besetzung hat die Universität sich selbst verpflichtet,
        bis 2028 klimaneutral zu werden. Dazu sollen nun eine Klimamanager*in
        für maximal zwei Jahre und eine Energiemanager*in unbefristet
        eingestellt werden. Jedoch ist gleichzeitig beim 1. Forum zu
        Klimagerechtigkeit angeklungen, dass es Jahre dauern würde, bis erst
        einmal die Informationen zum CO2-Ausstoß der verschiedenen Bereiche und
        Gebäude auf dem Campus gesammelt wären. Die Klimamanager*in müsste also
        tatkräftigt unterstützt werden. Um alle Bereiche in diesen Prozess
        miteinzugliedern, fordern wir ein partizipatives und aufeinander
        abgestimmtes Vorgehen. Grundlage ist dabei die Etablierung eines Green
        Office an der Uni Augsburg.
      </p>
      <br></br>
      <b>Stand der Dinge:</b>
      <p>
        Aktuell ist der Status Quo in Sachen Klimaneutralität an der Uni
        mangelhaft. Während die Energie- und Stromversorgung sicherlich für die
        meisten CO2-Emissionen verantwortlich sind, gibt die Uni vor, hierbei
        schon 100% klimaneutral zu sein. Jedoch ist dies bei näherer Betrachtung
        nicht der Fall.
      </p>
      <p>
        <i>Strom: </i>
        Wie die Augsburger Privathaushalte bezieht die Uni von den Augsburger
        Stadtwerken (SWA) Pseudoökostrom, nicht wahren Ökostrom (der bei
        Ökostromproduzenten eingekauft werden würde). Der Pseudoökostrom ist nur
        zertifikatsbasiert, d.h. anderswo steigt der Anteil an Kohle- und
        Gasstrom. "Anderswo" kann dabei auch schon bedeuten: Beim Strommix der
        Unternehmen, die über die SWA ihren Strom beziehen. Vielleicht ist es
        aber nicht (nur) das, sondern auch außerhalb Augsburgs, bei
        Stromvermittlern, die sich noch weniger für Ökostrom interessieren als
        die SWA. Durch den Pseudoökostrom der SWA werden jedenfalls keine neuen
        Wind- oder Solarparks errichtet.
      </p>
      <p>
        <i>Wärme: </i>
        Ähnlich verhält es sich bei der Wärmeversorgung. Aktuell speißt sich die
        Fernwärmeerzeugung in Augsburg zu 50% durch Müllverbrennung, zu 30%
        durch Gas und 20% Biogas. Dementsprechend ist eigentlich nur ein Fünftel
        der Versorgung wirklich klimaneutral produziert. Wenn Deutschland bis
        2045 klimaneutral werden will, muss auch die Struktur der Augsburger
        Fernwärmeversorgung verändert werden. Oder aber, man gibt weiter vor
        "klimaneutral" zu werden, indem wir unsere Emissionen im globalen Süden
        kompensieren und postkoloniale Strukturen reproduzieren (Siehe Forderung
        Dekolonialisierung). Die Uni sollte sich daher als Großverbrauerin
        einsetzen, wirklich klimagerechte Energieverträge abzuschließen.
      </p>
      <p>
        <i>Müll: </i>
        Auch schon bei vergleichsweise kleinen Maßnahmen, wie die Umstellung auf
        Recyclingpapier oder die Mülltrennung, versagt die Universität komplett:
        So wird Studierenden beispielsweise vor der Cafeteria ein
        Mülltrennungssystem vorgegaukelt, wärend jedoch der gesamte getrennte
        Müll dann im Restmüll landet und verbrannt wird. Naja, immerhin kommt
        der verbrannte Müll am Ende der extrem klimaneutralen Fernwärmeerzeugung
        wieder zugute!
      </p>
      <p>
        <i>Mobilität: </i>
        Anstelle den Anreiz zu senken, mit dem Auto zur Uni zu fahren -
        beispielsweise durch ein Augsburg-weites kostenloses Semesterticket für
        alle Universitätsmitglieder und die Einführung von Parkkosten für den
        motorisierten Individualverkehr am Campus - stellt es die Uni auf ihrer
        Website so dar, jetzt große Schritte in Richtung Klimaneutralität zu
        machen. Dafür würden nämlich die Fahrradständer modernisiert und bald
        seien{" "}
        <a
          href="https://www.uni-augsburg.de/de/campusleben/neuigkeiten/2023/02/08/gr%C3%BCnes-licht-f%C3%BCr-gr%C3%BCne-mobilit%C3%A4t/"
          rel="noreferrer"
          target="_blank"
        >
          {" "}
          sogar Ladestationen für E-Bikes{" "}
        </a>{" "}
        geplant. Wahnsinn, das ist so gut! Andere Unis sind in vielen Bereichen
        schon deutlich weiter!
      </p>
      <br></br>

      <h1 id="partizipationsmöglichkeiten">Partizipationsmöglichkeiten</h1>
      <p>
        Seit einem Urteil des Bundesverfassungsgerichts von 1973 gilt die{" "}
        <a
          href="https://de.wikipedia.org/wiki/Professorenmehrheit"
          rel="noreferrer"
          target="_blank"
        >
          Professor*innenmehrheit
        </a>{" "}
        in Deutschland. Demnach verfügt das Professorium in allen
        Entscheidungen, die Lehre und Forschung betreffen, über eine absolute
        Mehrheit an Stimmen in Gremien. Jedoch besteht diese Zusammensetzung im
        Normalfall auch in Gremien, die Entscheidungen treffen, welche weit über
        Lehre und Forschung hinaus gehen. Beispielsweise setzt sich an der Uni
        Augsburg die erweiterte Universitätsleitung aus der Universitätsleitung
        (Präsidentin, Kanzler und 3 Vizepräsidenten), 8 Dekan*innen, 6
        Professor*innen und schließlich noch jeweils einem Mensch aus dem
        Mittelbau und dem wissenschaftsstützenden Personal zusammen. Richtig
        progressiv, dass auch zwei Vertreter*innen der Studierendenvertretung
        auch mit in dem höchsten Gremium dieser Universität sitzen!
        <br></br>
        Genauer hat das Professorium hierbei also nicht 50% der Stimmen, sondern
        14 von 22 Stimmen - also mehr als 60% der Stimmen. Wobei ja die
        Präsidentin Doering-Manteuffel und Vizepräsident Dresel auch der Gruppe
        der Professorium angehören.
        <br></br>
        Interessanterweise liegt die{" "}
        <a
          href="https://de.wikipedia.org/wiki/FLINTA*"
          rel="noreferrer"
          target="_blank"
        >
          FLINTA*-Quote
        </a>{" "}
        in dem höchsten Gremium der Uni Augsburg bei 18% und entspricht damit
        wie maßgeschneidert dem Anteil der FLINTA*-Professorinnen.
        <br></br>
        <br></br>
        Zudem gilt seit 2006 das sogenannte "Augsburger Modell", wodurch es die
        damalige Unileitung geschafft hat, den Senat und die Unileitung
        zusammenzuziehen. Das unabhängige Gremium "Senat" gibt es nicht. In
        allen wichtigen Entscheidungen kann die Unileitung direkt
        mitentscheiden. Dass man nicht gerne gegen die eigene Chefin stimmt,
        ignorieren wir mal für den Moment. Jedoch ist es schon etwas
        erschreckend, wenn die Präsidentin in ihrem ersten Besuch im
        studentischen Konvent seit Amtsantritt (vor 12 Jahren!) am 01.02.2023
        behauptet, wir als Studierende können doch froh sein, dass die Uni
        Augsburg ein solch progressives Modell eingeführt hat - als einzige Uni
        in Deutschland. Denn so müsse wenigstens nicht so viel diskutiert werden
        und es käme gar nicht zu schwierigen Entscheidungen und Unstimmigkeiten.
        Hmm, woran das bloß liegt.
        <br></br>
        <br></br>
        Fakt ist, dass Studierende schlichtweg nichts zu sagen haben. Während
        sich etwa 100 Menschen in der Studierendenvertretung und in Fachschaften
        und Hochschulgruppen ehrenamtlich engagieren, Stunden in der Woche in
        einer Veränderung dieser Strukturen stecken und Sitzungen des
        studentischen Konvents meist bis Mitternacht dauern, werden sinnvolle Anträge
        dann einfach in den Mülleimer geworfen. So entsteht ein
        Teufelskreis was studentisches Engagement angeht: Studierende machen
        immermehr Frustrationserfahrungen, da ihre Mühe sowieso nichts bringt und ziehen ihr Engagement zurück.
        Naja, laut Präsidentin sei die Jugend eben unpolitisch geworden.
        <br></br>
        <br></br>
        Hilf uns dabei eine
        Koalition aus engagierten Universitätsmitgliedern aufzubauen und die Grundordnung auf einen klimagerechten und
        sozialverträglichen Weg zu bringen!
      </p>

      <br></br>

      <h1 id="dekolonialisierung">Dekolonialisierung</h1>

      <br></br>
      <br></br>

      <h1 id="Wissensspeicher">Wissensspeicher</h1>

      <ul>
        <li>
          <a
            href="pdf/Protokoll - 1. Forum (08.12.22).pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Protokoll - 1. Forum zu Klimagerechtigkeit der Uni (08.12.22)
          </a>
        </li>
        <li>
          <a
            href="pdf/Protokoll - Arbeitstag zur Governance und Unistrukturen (31.01.23).pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Protokoll - Arbeitstag zur Governance und Unistrukturen (31.01.23)
          </a>
        </li>
        <li>
          <a
            href="pdf/Grundordnung der Universität Augsburg2.0.pptx"
            target="_blank"
            rel="noopener noreferrer"
          >
            Präsentation - Änderungsvorschläge zur Grundordnung (31.01.23)
          </a>
        </li>
        <li>
          <a
            href="pdf/Grundordnung Uni.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Grundordnung Uni Augsburg (21.12.22)
          </a>
        </li>
        <li>
          <a href="pdf/BayHIG.pdf" target="_blank" rel="noopener noreferrer">
            Bayrisches Hochschulinnovationsgesetz (05.08.22)
          </a>
        </li>
        <li>
          <a href="pdf/StuVe.jpg" target="_blank" rel="noopener noreferrer">
            Struktur Studierendenvertretung
          </a>
        </li>
        <li>
          {" "}
          <a
            href="https://www.uni-augsburg.de/de/studium/studentisches-leben/studierendenvertretung/studentischer-konvent/beschlusse/"
            rel="noreferrer"
            target="_blank"
          >
            Beschlüsse des studentischen Konvents
          </a>{" "}
        </li>
      </ul>
      <div className="d-flex flex-column">
        <img
          style={{
            maxWidth: "500px",
            marginBottom: "25px",
          }}
          src="img/bild100.png"
          alt="Versammlungsgesetz"
        />
      </div>
    </>
  );
}
