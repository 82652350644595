import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function WerWirSind() {
  return (
    <>
      <h1 id="selbstverständnis">Selbstverständnis</h1>
      <p>
        Wir sehen uns als Teil der internationalen{" "}
        <a href="https://endfossil.de/" rel="noreferrer" target="_blank">
          End Fossil: Occupy
        </a>{" "}
        Bewegung, welche sich zum Ziel gesetzt hat, Bildungseinrichtungen zu
        besetzen und dadurch eine Veränderung herbeizuführen. Dabei stützen wir
        unsere Forderungen auf wissenschaftliche Erkenntnisse. Als
        antirassistischer, queer-feministischer, antiklassistischer und
        kapitalismuskritischer Zusammenschluss von Studierenden der Uni Augsburg
        wollen wir eine gesamtgesellschaftliche Transformation auf
        basisdemokratischen Werten begründen, sodass alle Menschen - sofern sie
        nicht gegen unsere Grundwerte verstoßen - sich uns anschließen können.
      </p>
      <br></br>

      <h1 id="unsereziele">Unsere Ziele</h1>
      <p>
        Unsere{" "}
        <a
          href="https://www.endfossilaugsburg.de/forderungen"
          rel="noreferrer"
          target="_blank"
        >
          Forderungen
        </a>{" "}
        reihen sich in die Ideale der Klimagerechtigkeitsbewegung ein.
        Klimagerechtigkeit ist eine komplexe globale Herausforderung, welche
        komplexe Lösungstrategien erfordert. Eine gesamtgesellschaftliche
        Transformation umfasst vielfältige Veränderung in allen Bereichen
        unserer Gesellschaft. Universitäten tragen als Bildungsinstitutionen
        besondere Verantwortung, denn sie haben eine transformative
        Strahlwirkung auf alle gesellschaftlichen Ebenen.
      </p>
      <br></br>
      <p>
        Die Forderungen beziehen sich konkret auf Veränderungen, die an unserer
        Universität im ersten Schritt notwendig sind. Diese sind nicht umfassend
        und werden sich in einem Prozess, an dem alle partizipieren können,
        stetig weiterentwickeln. Eine Veränderung und Präzisierung unserer
        Forderungen ist für uns wichtig, weil ständige (Selbst-)Reflexion ein
        essentieller Bestandteil von Transformation ist.
      </p>
      <br></br>

      <h1 id="befreundetegruppen">Befreundete Gruppen</h1>
      <p>
        In Augsburg passiert viel. Es gibt einige linke Gruppen, die sich um
        unterschiedliche Themengebiete wie Klimagerechtigkeit, soziale
        Gerechtigkeit, Dekolonialisierung, Wohnraum, Integration und kulturelle
        Vielfalt kümmern. Einige davon haben uns während der Besetzung
        unterstützt oder sind einfach nur sehr cool, weshalb wir sie hier für
        euch aufgelistet haben. Jede dieser Gruppen freut sich über
        Unterstützung. Wir kämpfen alle an unterschiedlichen Stellen für eine
        Veränderung dieses Systems. Vielen Dank für eure Arbeit und dass ihr
        weiter kämpft - jeden Tag:<br></br>
        <br></br>
        Klimacamp Augsburg<br></br>
        Augsburg für Alle<br></br>
        Knoblauchfahne<br></br>
        Oben Ohne Kollektiv<br></br>
        Unser Haus e.V.<br></br>
        Pa*radieschen Augsburg<br></br>
        Die ganze Bäckerei<br></br>
        F*Streik Augsburg<br></br>
        Autonome Bande Auxburg<br></br>
        GEW Hochschulgruppe Augsburg<br></br>
      </p>
      <p>
        Einen tollen Überblick über linke Veranstaltungen und Strukturen in
        Augsburg gibt es bei{" "}
        <a href="https://auxpunks.uber.space/" rel="noreferrer" target="_blank">
          AuxPunks
        </a>
        !
      </p>
      <br></br>

      <h1 id="endfossil">Die End Fossil-Bewegung</h1>
      <p>
        Wir sind Teil der internationalen EndFossil: Occupy!-Bewegung, die sich
        zum Ziel gesetzt hat, durch die Besetzung von Bildungseinrichtungen die
        Klimagerechtigkeitsbewegung auf eine neue Stufe zu heben. Es braucht
        kollektiven Widerstand der jungen Generationen, um einen tatsächlichen
        System Change zu erzeugen. Denn wir wissen, dass die Politik nicht
        handeln wird...
      </p>
      <p>
        <a href="https://endfossil.com/" rel="noreferrer" target="_blank">
          End Fossil International
        </a>
      </p>
      <p>
        <a href="https://endfossil.de/" rel="noreferrer" target="_blank">
          End Fossil Deutschland
        </a>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <div className="d-flex flex-column">
          <img
            style={{
              maxWidth: "500px",
              marginBottom: "25px",
            }}
            src="img/banner 1.png"
            alt="Banner See"
          />
        </div>
      </p>
    </>
  );
}
