import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

interface NavbarItemProps {
  text: string;
}

export function NavbarItem(props: NavbarItemProps): JSX.Element {
  return (
    <span
      className="headerText"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      {props.text}
    </span>
  );
}
