import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { CollapsibleCard } from "../components/CollapsibleCard";

export function Forderungen() {
  return (
    <>
     <br></br>
      <p>
      Unsere Forderungen reihen sich in die Ideale der Klimagerechtigkeit ein. Klimagerechtigkeit ist eine komplexe globale Herausforderung, welche komplexe Lösungstrategien erfordert. Eine gesamtgesellschaftliche Transformation umfasst vielfältige Veränderungen in allen Bereichen unserer Gesellschaft. Universitäten tragen dabei als Bildungsinstitutionen besondere Verantwortung, denn sie haben eine transformative Strahlwirkung auf alle gesellschaftlichen Ebenen.
      </p>
      <br></br>
      <h1 id="forderungen">Forderungen</h1>
      <b>
        Die Forderungen beziehen sich auf konkrete, im ersten Schritt an unserer Universität notwendige Veränderungen. Diese sind nicht umfassend und werden sich in einem Prozess, an dem alle partizipieren können, stetig weiterentwickeln. Eine Veränderung und Präzisierung unserer Forderungen ist für uns wichtig, weil ständige (Selbst-)Reflexion ein essentieller Bestandteil von Transformation ist.
      </b>
      <br></br>
      <br></br>
      {/* <!-- Partizipation --> */}
      <CollapsibleCard
        id="Partizipation"
        title={"Partizipation"}
        content={
          <>
            <p>
            Wir fordern die <i>gleichberechtigte Partizipation aller Bereiche bei allen universitären Entscheidungen</i>. In den Gremien sollte die Zusammensetzung der vier Statusgruppen (Studierende, Professorium, Mittelbau und wissenschaftsstützendes Personal) paritätisch sein. Aktuell hat die Gruppe des Professoriums bei allen Entscheidungen eine absolute Mehrheit. Dies ist in keinem Fall im Interesse tatsächlicher demokratischer Hochschulorganisation. Folglich fordern wir:
            </p>
          
            <ul>
              <li>
              Verbindlichkeit von Beschlüssen des studentischen Konvents, des Konvents des Mittelbaus und des Konvents des wissenschaftsstützenden Personals stärken
               <ul>
                 <li>Transparente und öffentliche Begründung der Entscheidung der EULe bezüglich jedes Konventsantrags
                 </li>
                </ul>
              </li>
              <li>
               Statusgruppen-übergreifende Arbeitsgruppen zu aktuellen Themen, offen für alle Universitätsmitglieder (beispielsweise die Etablierung eines Green Office)
              </li>
              <li>
               Diskussionsräume zu aktuellen Themen etablieren (beispielsweise zu Demokratie, Krieg in der Ukraine und Klimagerechtigkeit)
              </li>
              <li>
               Selbstverwaltete Räume und Safer Spaces am Campus 
               <ul>
                 <li>Insbesondere für Minorities: Haben diese keine Räume, können sie sich auch nicht organisieren und ihre Anliegen hervorbringen
                 </li>
               </ul>
              </li>
              <br></br>
           </ul> 

           <p>
             Wir Studierende haben - wie auch die anderen universitären Bereiche - ein Recht auf inneruniversitäre Mitbestimmung auf allen Ebenen. Eine Universität kann es sich nicht leisten, die demokratische Mitbestimmung der Studierendenschaft zu verhindern. Um diese Partizipationsmöglichkeiten wiederzuerlangen, fordern wir:
            </p>

          <ul>
            <li>
               Die sofortige Wiedereinführung der Verfassten Studierendenschaft
               <ul>
                 <li>Verankerung der studentischen Selbstverwaltung in der Grundordnung der Universität Augsburg
                 </li>
               </ul>
             </li>
             <li>
               Eine 3%-Klausel statt der 5%-Klausel für studentische Universitätsvollversammlungen
              </li>
            <li> Die Uni soll hochschulpolitisches Engagement der Studierenden stärker unterstützen
               <ul>
                 <li>Die Universität muss sich an der Bewerbung der Studierendenvertretung (StuVe) beteiligen - Konkret bedeutet das die Bewerbung der Wahl des Studentischen Konvents, der Vollversammlung sowie bessere Übersichtlichkeit der Organe und Organisation der StuVe
                 </li>
               </ul>
              </li>
              <li>
               Mehr Räume unter der Verwaltung der Studierendenvertretung (StuVe)
               <ul>
               <li>Erleichterung des Zugangs, um Hörsäle für studentisch organisierte Veranstaltungen wie Vorträge, Diskussionsrunden oder kulturelle Veranstaltungen nutzen zu können
               </li>
               </ul>
              </li>
              <li>
              Ersatz für den 2021 abgeschafften Frei*Raum als Begegnungsort für Studierende, damit kritischer Diskurs und Partizipation überhaupt entstehen kann
              </li>
            </ul>
            
            <p>
            Wir sind keine Lernfabrik! Universität besteht aus mehr als Aufenthalt und Arbeit. Eine Universität ist ein pulsierendes Zentrum studentischen Lebens, ein Knotenpunkt der urbanen Kultur. Wir brauchen Platz zum Lernen, Lehren, Forschen und zum Leben. Freie Räume und demokratische Mitbestimmung für alle!
            </p>
          </>
        }
      />
      {/* <!-- Transparenz --> */}
      <CollapsibleCard
        id="transparenz"
        title={"Transparenz"}
        content={
          <>
            <p>
             Transparenz stellt die Grundlage für eine partizipative, demokratisch gestaltete Universität dar. Um den Zugang zu Gremien zu ermöglichen und allen Universitätsmitgliedern überhaupt erst die Möglichkeit zu geben, sich über universitäre Prozesse und Entscheidungen eine Meinung bilden zu können, fordern wir:
            </p>
         
            <ul>
              <li>
               Die Entscheidungen von der Unileitung und allen Gremien mit Begründung zu veröffentlichen
               <ul><li>Protokolle der Sitzungen auf der Website der Universität im Rahmen des Datenschutzes offenlegen - beispielsweise auf der Website der Universität</li></ul>
              </li>
              <li>
               Bessere Übersichtlichkeit über Gremien und Möglichkeiten der Partizipation
               <ul><li>Digitalen Raum schaffen für Archivierung und Wissensweitergabe</li></ul>
              </li>
              <li>
               Den Prozess zur Klimaneutralität bis 2028 transparent darstellen:
               <ul><li>In Form eines Jahresberichts</li></ul>
               <ul><li>Regelmäßige Treffen veranstalten, um Zwischenziele darzustellen und Partizipationsräume zu eröffnen</li></ul>
               <ul><li>Durch die Etablierung eines Green Office</li></ul>
              </li>
              <li>
               Transparenz in den fiskalischen Angelegenheiten der Universität
               <ul><li>Veröffentlichung des Haushalts der Universität in Form eines detaillierten Jahresberichts</li>
               <li>Das Offenlegen der Geldanlagen und Investitionen der Universität</li></ul>
              </li>
              <li>
               Transparente Aufklärung über die Rolle von Universitäten in der Rüstungsforschung
               <ul><li>Um der Militarisierung der Gesellschaft in Folge globaler Konflikte entgegenzuwirken</li></ul>
               <ul><li>Eine kritische Reflexion der Verschwiegenheitsklausel</li></ul>
              </li>
            </ul>
          </>
        }
      />
      {/* <!-- Soziales --> */}
      <CollapsibleCard
        id="soziales"
        title={"Soziale Uni"}
        content={
          <>
            <p>
            Um im Sinne einer sozial-gerechten Universität dem Prinzip der Chancengleichheit nachzukommen, besteht in Augsburg ein enormer Nacholbedarf. Das Recht auf Bildung darf nicht nur Privilegierteren vorbehalten sein. Für die Studierenden fordern wir:
            </p>
            <ul>
              <li>
              Studierende vor Inflation schützen (beispielsweise keine Erhöhung des Semesterbeitrags und der Preise in Mensen und Cafeterien)
              </li>
              <li>
              Universität muss sich um eine angemessene Wohnsituation der Studierenden kümmern
              <ul><li>Dabei sollen Studierende Mitspracherecht haben</li></ul>
              </li>
            </ul>

            <ul>
              <li>
              Zwar gibt es bereits einige Formen der Unterstützung für Universitätsangehörige, jedoch müssen diese ausgebaut und zugänglicher gemacht werden:
              <ul>
                <li>Die Umsetzung der Barrierefreiheit</li>
                <li>Gerechter Zugang zu Studienmitteln (z.B. Software, Kameras, Aufnahmegeräte)</li>
                <li>Eine elternfreundliche Universität durch Familienbetreuung am Campus schaffen</li>
                <li>Ein sinnvolles und ausreichendes Angebot der psycho-sozialen Unterstützung und Betreuung am Campus</li>
              </ul>
              </li>
            </ul>
           
            <p>
            In den Bereichen des akademischen Mittelbaus, des wissenschaftsstützenden Personals und des Studierendenwerks sind Maßnahmen genauso überfällig, um soziale Gerechtigkeit zu gewährleisten:
            </p>
            <ul>
              <li>Aufnahme aller studentischen Beschäftigten der Universität im Sinne eines <a href="https://tvstud.de/" rel="noreferrer" target="_blank">
              TVStud
              </a></li>
              <li>Eine faire Bezahlung für Lehraufträge
              <ul><li>Dabei ist die Laufzeit von Tarifverträgen wichtig (dauerhafte Lohnerhöhung statt Einmalzahlungen)</li></ul>
              </li>
            </ul>


          </>
        }
      />
      {/* <!-- Dekolonialisierung --> */}
      <CollapsibleCard
        id="dekolonialisierung"
        title={"Dekolonialisierung"}
        content={
          <>
            <p>
            Unserer Universität muss dem kolonialen Erbe der westlichen Wissenschaften - insbesondere dem Eurozentrismus - kritisch gegenübertreten: Nach fünf Jahrhunderten Kolonalismus haben sich diese Denkmuster tief in alle gesellschaftlichen Ebenen eingebrannt. Dieses Trauma kann nicht innerhalb von 50 Jahren überwunden werden. So werden rassistische, patriarchale, hierarchische und antiemanzipatorische Strukturen unreflektiert weitergegeben und verfestigt. Wir fordern daher:
            </p>
            <ul>
              <li>
              Die Etablierung einer Statusgruppen-übergreifenden Arbeitsgruppe, welche eine Strategie zur Dekolonialisierung der Universität erarbeitet
              </li>
              <li>
              Lehrinhalte aller Fachbereiche müssen überprüft und überarbeitet werden, sodass koloniales Gedankengut nicht mehr unreflektiert an die nächste Generation weiter gegeben wird
              </li>
              <li>
              Dekoloniale Arbeit und postkoloniale Perspektiven müssen zum Bestandteil der Lehre in allen Fachrichtungen werden und in Entscheidungsprozessen an unserer Universität berücksichtigt werden
              </li>
            </ul>
            <p>
            In einer globalisierten Gesellschaft, die nur gemeinsam den Herausforderungen des 21. Jahrhunderts gegenübertreten kann, ist es auch unsere Aufgabe neokoloniale Strukturen zu überwinden und globalen Abhängigkeitsverhältnissen entgegenzuwirken.
            </p>
          </>
        }
      />

      {/* <!-- Echte Klimaneutralität --> */}

      <CollapsibleCard
        id="klimaneutralität"
        title={"Keine Schein-Klimaneutralität"}
        content={
          <>
            <p>Dass es zivilen Ungehorsam brauchte, damit die Universität das Ziel der Klimaneutralität bis 2028 verabschiedet, ist schon bezeichnend. Jetzt muss die Universität ins Handeln kommen und nicht weiter Greenwashing betreiben. Es braucht daher:</p>
            <ul>
              <li>
              Die Etablierung eines Green Office als Knotenpunkt für Klimaneutralitäts- und Biodiversitätsprojekte
               <ul>
                <li>
                Statusgruppen-übergreifende Arbeitsgruppen zu Klimaneutralität und Biodiversität
                </li>
                <li>
                Die Gestaltung eines transparenten und partizipativen Prozesses zur Klimaneutralität, wie in der Forderung <i>Transparenz</i> beschrieben.
                </li>
                <li>
                Reflexion des eigenen Klimaneutralitätsbegriffs
                </li>
                <li>
                Die Universität Augsburg soll in ein Biotop der Artenvielfalt umgewandelt werden. Die Voraussetzungen als grüne Campus Uni sind dafür ideal.
                </li>
               </ul>
              </li>
              <li>
               Die Wärmeversorgung wirklich klimaneutral gestalten
               <ul>
                <li>
                Tatsächlich klimaneutrale Fernwärme, die langfristig nicht auf 50% Müllverbrennung und 30% Gasverbrennung beruhen kann
                </li>
                <li>
                Sanierung der Universität unter energetischen Gesichtspunkten (z.B. Isolierung, Modernisierung der Heizungsanlage, Maßnahmen zur Einsparung von Gas)
                </li>
               </ul>
              </li>
              <li>
               Eine Stromversorgung die nicht auf Kohle basiert
                <ul>
                  <li>
                  Kündigung der Verträge mit Energieanbietern, die Kohlestrom produzieren
                  </li>
                  <li>
                  Energieerzeugung am Campus stärken durch den Ausbau von Fotovoltaikanlagen und Solarthermie
                  </li>
                </ul>
              </li>
            </ul>
            <p>
             Zudem sind weitere Maßnahmen notwendig. Dazu gehört:
            </p>
            <ul>
              <li>
              Die Förderung von klimafreundlicher Mobilität
              </li>
              <li>
              Ein regionales, pflanzenbasiertes und klimafreundliches Angebot in den Mensen und Cafeterien
              </li>
            </ul>
            <p>Die Universität muss sich ihrer Verantwortung stellen. Partizipative Formate wie das von uns ins Leben gerufene Forum zu Klimagerechtigkeit sind notwendige Konzepte, um alle Universitätsmitglieder an dem Prozess teilhaben zu lassen und Synergieeffekte zu erzeugen.</p><br></br>
            <br></br>
            <br></br>
          </>
        }
      />
    </>
  );
}
