import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function Presse() {
  return (
    <>
      <h2 id="presse">Presse</h2>
      <ul>
        <li>
          <a
            href="https://www.augsburger-allgemeine.de/augsburg/augsburg-uni-schreitet-bei-demokratie-debatte-von-studierenden-ein-id65345391.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Uni schreitet bei Demokratie-Debatte von Studierenden ein
            (31.01.2023)
          </a>
        </li>
        <li>
          <a
            href="https://www.forumaugsburg.de/s_6kultur/Wissenschaft/221221_erstes-oeffentliches-forum-tagt-an-der-uni-erfolg-f%C3%BCr-end-fossil-augsburg/index.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            „Erstes öffentliches Forum“ tagt an der Uni - ein Riesenerfolg für
            End Fossil Augsburg (21.12.2022)
          </a>
        </li>
        <li>
          <a
            href="https://presstige.org/2022/12/end-fossil-augsburg-kaempft-fuer-mehr-klimagerechtigkeit/"
            target="_blank"
            rel="noopener noreferrer"
          >
            End Fossil Augsburg kämpft für mehr Klimagerechtigkeit (09.12.2022)
          </a>
        </li>
        <li>
          <a
            href="https://www.augsburger-allgemeine.de/augsburg/augsburg-studierendenvertreter-der-uni-stellen-sich-hinter-hoersaalbesetzer-id64787626.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Studierendenvertreter der Uni stellen sich hinter Hörsaalbesetzer
            (06.12.2022)
          </a>
        </li>
        <li>
          <a
            href="https://www.augsburger-allgemeine.de/augsburg/augsburg-die-uni-augsburg-will-bis-2028-klimaneutral-werden-id64751111.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Die Uni Augsburg will bis 2028 klimaneutral werden (03.12.2022)
          </a>
        </li>
        <li>
          <a
            href="https://www.augsburger-allgemeine.de/augsburg/augsburg-klimaprotest-hoersaalbesetzer-lehnen-gespraech-mit-der-unileitung-ab-id64736836.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Klimaprotest: Hörsaalbesetzer lehnen Runden Tisch mit Unileitung ab
            (01.12.2022)
          </a>
        </li>
        <li>
          <a
            href="https://www.br.de/nachrichten/bayern/klimaprotest-in-augsburg-hoersaalbesetzer-lehnen-gespraech-ab,TOk31LE"
            target="_blank"
            rel="noopener noreferrer"
          >
            Klimaprotest in Augsburg - Hörsaalbesetzer lehnen Gespräch ab
            (01.12.2022)
          </a>
        </li>
        <li>
          <a
            href="https://presse-augsburg.de/klimaktivisten-besetzen-hoersaal-1-leitung-der-universitaet-augsburg-laedt-zu-rundem-tisch-ein/833685/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Klimaktivisten besetzen Hörsaal 1 - Leitung der Universität Augsburg
            lädt zu Rundem Tisch ein (30.11.2022)
          </a>
        </li>
        <li>
          <a
            href="https://www.klassegegenklasse.org/besetzung-fuer-klimagerechtigkeit-und-studentische-mitsprache-an-der-universitaet-augsburg/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Besetzung für Klimagerechtigkeit und studentische Mitsprache an der
            Universität Augsburg! (30.11.2022)
          </a>
        </li>
        <li>
          <a
            href="https://www.uni-augsburg.de/de/campusleben/neuigkeiten/2022/11/30/runder-tisch-klimaneutralit%C3%A4t/"
            target="_blank"
            rel="noopener noreferrer"
          >
            „Runder Tisch“ zum Thema Klimaneutralität (30.11.2022)
          </a>
        </li>
        <li>
          <a
            href="https://www.augsburger-allgemeine.de/augsburg/augsburg-wegen-hoersaalbesetzung-uni-laedt-klimaaktivisten-zum-gespraech-ein-id64729901.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wegen Hörsaalbesetzung: Uni lädt Klimaaktivisten zum Gespräch ein
            (30.11.2022)
          </a>
        </li>
        <li>
          <a
            href="https://www.augsburger-allgemeine.de/augsburg/augsburg-studierende-besetzen-grossen-hoersaal-auf-dem-augsburger-unicampus-id64703191.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Studierende besetzen größten Hörsaal auf dem Augsburger Unicampus
            (30.11.2022)
          </a>
        </li>
        <li>
          <a
            href="https://www.daz-augsburg.de/91216-2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Studierende erheben Forderungen an die Universität Augsburg
            (23.11.2022)
          </a>
        </li>
        <li>
          <a
            href="https://www.augsburger-allgemeine.de/augsburg/augsburg-klimaaktivisten-protestieren-an-der-universitaet-augsburg-id64650021.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Klimaaktivisten protestieren an der Universität Augsburg
            (23.11.2022)
          </a>
        </li>
      </ul>
      <h2>Pressemitteilungen</h2>
      <ul>
        <li>
          <a
            href="pdf/Ist die Universitätsleitung nicht bereit für Veränderungen.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ist die Universitätsleitung nicht bereit für Veränderungen?
            (30.01.2023)
          </a>
        </li>
        <li>
          <a
            href="pdf/Studentische Partizipation soll ausgeweitet werden.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Studentische Partizipation soll ausgeweitet werden (09.12.2022)
          </a>
        </li>
        <li>
          <a
            href="pdf/Universitätsleitung geht auf Forderung der Besetzenden ein.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Universitätsleitung geht auf Forderung der Besetzenden ein
            (06.12.2022)
          </a>
        </li>
        <li>
          <a
            href="pdf/Statement - Warum End Fossil Augsburg morgen nicht mit der Unileitung verhandelt.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Statement - Warum End Fossil Augsburg morgen nicht mit der
            Unileitung verhandelt
          </a>
        </li>
        <li>
          <a
            href="pdf/Studierende besetzen den größten Hörsaal der Universität Augsburg.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Studierende besetzen den größten Hörsaal der Universität Augsburg
            (28.11.2022)
          </a>
        </li>
        <li>
          <a
            href="pdf/Studierende erheben Forderungen an die Universität Augsburg.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Studierende erheben Forderungen an die Universität Augsburg
            (21.11.2022)
          </a>
        </li>
      </ul>
    </>
  );
}
