import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

export function Footer(): JSX.Element {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col py-4">
              <h3 id="contact">Kontakt</h3>
              <br />
              <a
                className="mailLink"
                href="mailto:endfossilaugsburg@riseup.net"
              >
                endfossilaugsburg [at] riseup [punkt] net
              </a>
            </div>
            <div className="col py-4">
              <h3 id="insta">Social Media</h3>
              <a
                href="https://www.instagram.com/endfossil.augsburg/"
                target="_blank"
                className="me-2"
                rel="noopener noreferrer"
              >
                <i className="bi bi-instagram footerIcons"></i>
              </a>
              <a
                href="https://t.me/+12nZr6xwo9dkMzdi"
                target="_blank"
                className="mx-2"
                rel="noreferrer"
              >
                <i className="bi bi-telegram footerIcons"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
