import { Routes, Route, Outlet } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { Aktuelles } from "./views/Aktuelles";
import { Chronologie } from "./views/Chronologie";
import { Forderungen } from "./views/Forderungen";
import { Partizipation } from "./views/Partizipation";
import { Presse } from "./views/Presse";
import { Transparenz } from "./views/Transparenz";
import { WerWirSind } from "./views/WerWirSind";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { WebsiteStatistik } from "./views/WebsiteStatistik";

export default function App() {
  let x = 0;
  let y = 0;
  document.addEventListener("mousemove", function (event) {
    x = event.clientX;
    y = event.clientY;
  });

  const sessionId = Date.now().toString(36) + Math.random().toString(36);
  sessionStorage.setItem("sessionID", sessionId);

  setInterval(() => {
    try {
      const visitorData = {
        url: window.location.href,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
        visibilityState: document.visibilityState,
        mouseX: x,
        mouseY: y,
        deviceInformation: navigator.userAgent,
        lastWebsite: document.referrer,
        sessionID: sessionStorage.getItem("sessionID"),
      };
      void fetch(
        "https://statistic-api.azurewebsites.net/api/addVisitorData?code=veFBZWJqZEpYPXM4CK9l8wTnUKc9SIZ8LqARE9DrH4a_AzFupYEqoQ==",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Keep-Alive": "true",
          },
          body: JSON.stringify(visitorData),
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, 10000);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Aktuelles />} />
        <Route path="/chronologie" element={<Chronologie />} />
        <Route path="/forderungen" element={<Forderungen />} />
        <Route path="/partizipation" element={<Partizipation />} />
        <Route path="/presse" element={<Presse />} />
        <Route path="/transparenz" element={<Transparenz />} />
        <Route path="/werwirsind" element={<WerWirSind />} />
        <Route path="/website-statistik" element={<WebsiteStatistik />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <>
      <Navbar />
      <main className="container my-4">
        <div className="col">
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  );
}
